const FooterData = {
  SocialMedia: {
    Facebook:
      "https://www.facebook.com/profile.php?id=61552333749894",
    Instagram:
      "https://www.instagram.com/fanous.clinic/",
    Linkedin:
      "https://www.linkedin.com/company/fanousclinic/?viewAsMember=true",
  },
  Blogs: "/blogs",
  AboutUs: "/about",
  ContactUs: "/contact",
  Mail: "mailto:info@fanousclinic.com",
  Phone: "tel:(980) 224-7288",
  Address:
    "https://www.google.com/maps/place/Fanous+Clinic/@35.0476396,-82.3222337,8z/data=!4m6!3m5!1s0x88569b4acb6e1bc9:0x7eb32e7ab2a264b2!8m2!3d35.1173334!4d-80.9592065!16s%2Fg%2F11vrdzj83_?entry=ttu",
};

export default FooterData;
