export const usersData = [
    {
        slug: "dr-hany-farag",
        title: "",
        description: "",
        keywords: "",
        imageUrl: "/images/home_3/dr-hany-farag-primary-care-physician.webp",
        imageAlt: "Dr. Hany Farag, MD MBA - Primary Care Physician at Fanous Clinic in Charlotte, NC",
        name: "Hany Farag, MD MBA",
        pos: "Primary Care Physician",
        subPos: "Dr. Hany Farag is a skilled Primary Care Physician with expertise in preventive care and patient-centered medical services. He combines his medical knowledge with business acumen to deliver comprehensive and personalized healthcare.",
        phone: "(216) 659-5992",
        email: "hany.farag.md@fanousclinic.com",
    },
    {
        slug: "abdo",
        title: "",
        description: "",
        keywords: "",
        imageUrl: "",
        imageAlt: "",
        name: "abdo",
        pos: "doctor",
        subPos: "Primary Care Physician at Fanous Clinic in Charlotte, NC",
        phone: "(960) 333-3333",
        email: "abdo@gmail.com",
    },
]