import React, { useEffect } from "react";
import parse from "html-react-parser";
import { Box, Grid } from "@mui/material";
import NewsLetterForm from "../forms/NewsletterForm/NewsLetterForm.tsx";

export default function NewsletterStyle4({ title, subTitle }) {
  return (
    <Grid
      container
      rowGap={3}
      className="cs-newsletter cs-style-4 container"
      sx={{
        width: "100%",
        paddingY: "40px",
        paddingX: {
          lg: "0 !important",
          md: "0 !important",
          sm: "3rem",
          xs: "3rem",
        },
      }}>
      <Grid
        item
        lg={4}
        md={12}
        sm={12}
        xs={12}
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: {
            lg: "left",
            md: "center",
            sm: "center",
            xs: "center",
          },
          width: "100%",
        }}>
        <h2 className="cs-newsletter-title cs-white-color">
          {parse(title)}
        </h2>
        <p className="cs-newsletter-subtitle mb-0">{parse(subTitle)}</p>
      </Grid>
      <Grid
        item
        lg={7}
        md={12}
        sm={12}
        xs={12}>
        <NewsLetterForm
          formId="newsletter-form"
          emailJsTemp={{
            en: "fcweb-en-newsletter-emp",
            es: "fcweb-es-newsletter-emp",
          }}
          darkBg
          type="newsletter"
        />
      </Grid>
    </Grid>
  );
}
