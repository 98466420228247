"use client";

import React from "react";
import MenuWidget from "../Widget/MenuWidget";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import TextWidget from "../Widget/TextWidget";
import SocialWidget from "../Widget/SocialWidget";
import NewsletterStyle4 from "../Widget/NewsletterStyle4";
import { useLocale } from "next-intl";
import { usePathname } from "next/navigation";
import { useTranslations, useMessages } from "next-intl";
import { Grid } from "@mui/material";
import { usersData } from "../../(defaultLayout)/[locale]/(users)/users-details/users-data";

export default function FooterStyle2() {
  const t = useTranslations("footer");
  const messages = useMessages();
  const currentLang = useLocale();
  const noFramePages = ["opt-in", ...usersData.map((user) => user.slug)];
  const route = usePathname().substring(
    usePathname().lastIndexOf("/") + 1
  );

  const fromJson = (keys, basePath, keyFields) => {
    const newArr = keys.map((key) => {
      const item = {};
      keyFields.forEach((field) => {
        item[field] = t(
          `${basePath}.${key}.${field}`,
          route === "es" || "en"
            ? {
                currentLang: currentLang,
              }
            : null
        );
      });
      return item;
    });
    return newArr;
  };
  const menuDataOneLinks = [
    `/${currentLang}/opt-in`,
    `/${currentLang}/#testimonials`,
    `/${currentLang}/blogs`,
    `/${currentLang}/services`,
  ];
  const menuDataTwoLinks = [
    `/${currentLang}/about`,
    `/${currentLang}/contact`,
    `/${currentLang}/privacy-policy`,
    `/${currentLang}/terms-conditions`,
  ];

  if (noFramePages.includes(route)) {
    return null;
  } else {
    return (
      <footer className="cs-footer cs-style-2 cs-primary-bg cs-white-color cs-mt-40">
        <NewsletterStyle4
          title={t("newsletter.title")}
          subTitle={t("newsletter.subTitle")}
        />
        <div className="container">
          <Grid
            container
            spacing={3}
            sx={{ margin: "1rem 0" }}>
            <Grid
              item
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="cs-footer-item">
              <div className="cs-footer-item">
                <TextWidget
                  logoUrl="/images/hlogo2.webp"
                  text=" "
                />
                <ContactInfoWidget />
              </div>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              sm={12}
              xs={12}>
              <div className="cs-footer-item">
                <p style={{ fontWeight: "600" }}>
                  {t("workingHoursTitle")}
                </p>
                <ul className="cs-menu-widget cs-mp0">
                  {fromJson(
                    Object.keys(messages.footer.workingHours),
                    "workingHours",
                    ["day"]
                  ).map((item, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 1,
                      }}>
                      <p style={{ color: "#41bbcb", width: "7rem" }}>
                        {item.day}
                      </p>
                      <p
                        style={{
                          fontWeight: "500",
                          opacity: item.time === "Closed" ? 0.6 : 1,
                        }}>
                        &nbsp;
                        {index === 2
                          ? "11:00am - 7:00pm "
                          : index === 5
                            ? currentLang === "en"
                              ? "By Appointment"
                              : "Solo con cita previa"
                            : index === 6
                              ? currentLang === "en"
                                ? "Closed"
                                : "Cerrado"
                              : "8:00am - 5:00pm"}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              md={6}
              sm={6}
              xs={12}
              sx={{ order: { lg: 3, md: 4, sm: 4 } }}>
              <div className="cs-footer-item">
                <MenuWidget
                  data={fromJson(
                    Object.keys(messages.footer.menuDataOne),
                    "menuDataOne",
                    ["title"]
                  )}
                  links={menuDataOneLinks}
                />
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              md={6}
              sm={6}
              xs={12}
              sx={{ order: { lg: 4, md: 3, sm: 3 } }}>
              <div className="cs-footer-item">
                <MenuWidget
                  data={fromJson(
                    Object.keys(messages.footer.menuDataTwo),
                    "menuDataTwo",
                    ["title"]
                  )}
                  links={menuDataTwoLinks}
                />
                <SocialWidget />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="cs-footer-bottom">
          <div className="container">
            <div className="cs-copyright">{t("copyright")}</div>
          </div>
        </div>
      </footer>
    );
  }
}
