"use client";

import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocale, useTranslations } from "next-intl";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomImage from "../../CustomImage/CustomImage";

export default function BookingForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const currentLang = useLocale();
  function formatPhoneNumber(phoneNumber) {
    if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else {
      return phoneNumber;
    }
  }

  const isLg = useMediaQuery("(min-width: 991px)");
  const isMd = useMediaQuery("(min-width: 768px) and (max-width: 991px)");
  const isSm = useMediaQuery("(min-width: 768px) and (max-width: 767px)");
  const isXs = useMediaQuery("(max-width: 575px)");

  const boxClass = classNames({
    "col-lg-4": isLg,
    "col-md-4": isMd,
    "col-sm-12": isSm,
    "col-xs-12": isXs,
  });
  const phoneClass = classNames({
    "col-lg-3": isLg,
    "col-md-3": isMd,
    "col-sm-12": isSm,
    "col-xs-12": isXs,
  });
  const emailClass = classNames({
    "col-lg-5": isLg,
    "col-md-5": isMd,
    "col-sm-12": isSm,
    "col-xs-12": isXs,
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = handleSubmit((data, e) => {
    emailjs
      .send(
        "service_pjujdf9",
        `fcweb-${currentLang}-booking-emp`,
        {
          name: data.name,
          email: data.email,
          phone: formatPhoneNumber(data.phone),
        },
        "yR82CfaRwg7YEBsWj"
      )
      .then(
        (result) => {
          setSubmitted(true);
          window.gtag("event", "conversion", {
            send_to: "AW-11459720490/F45sCLrG79MZEKrqtgdq",
          });
          reset();
        },
        (error) => {
          toast.error("Failed to send Booking request");
        }
      );
  });
  const t = useTranslations("forms");
  const AL = useTranslations("ariaLabels");

  return (
    <>
      {submitted ? (
        <div
          style={{
            padding: "0px 20px",
            textAlign: "center",
          }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <CheckCircleRoundedIcon
              sx={{ color: "#41bbcb", fontSize: "4rem", mb: 1 }}
            />
          </div>
          <h3 style={{ marginBottom: "2rem", textAlign: "center" }}>
            {t("bookingForm.successTitle")}
          </h3>
          <p style={{ color: "black" }}>
            {t("bookingForm.successMessage")}{" "}
          </p>
          <Typography
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              color: "#033449",
            }}
            onClick={() => {
              setSubmitted(false);
            }}>
            {t("rebook")}
          </Typography>
        </div>
      ) : (
        <form
          id="booking-form"
          onSubmit={(e) => handleSubmit(onSubmit(e))}
          className=" cs-style-1 cs-white-bg cs-radius-30"
          style={{ width: "95%", margin: "0 auto" }}>
          <div
            className="container"
            style={{
              padding: 0,
              marginBottom: "1rem",
            }}>
            <div className="cs-banner-text">
              <h2 className="cs-banner-title cs-fs-72">
                {t("bookingForm.title")}
              </h2>
              <p className="cs-banner-subtitle cs-fs-20 mb-0 cs-heading-color">
                {t("bookingForm.description")}
              </p>
            </div>
          </div>
          <div className="row">
            <Box
              className={boxClass}
              style={{
                marginBottom: "1rem",
              }}>
              <label className="cs-input-label cs-heading-color">
                {t("fields.name")}
              </label>
              <input
                aria-required="true"
                {...register("name", {
                  required: t("validation.required"),
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: t("validation.string"),
                  },
                })}
                type="text"
                className="cs-form-field"
                placeholder={t("placeholders.name")}
                aria-label={AL("inputs.name")}
              />
              {errors.name && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginBottom: 0,
                  }}>
                  {errors.name.message}
                </p>
              )}
            </Box>
            <Box
              className={emailClass}
              style={{
                marginBottom: "1rem",
              }}>
              <label className="cs-input-label cs-heading-color">
                {t("fields.email")}
              </label>
              <input
                aria-required="true"
                {...register("email", {
                  required: t("validation.required"),
                  pattern: {
                    value:
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: t("validation.notValidEmail"),
                  },
                })}
                type="email"
                className="cs-form-field"
                placeholder={t("placeholders.email")}
                aria-label={AL("inputs.email")}
              />
              {errors.email && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginBottom: 0,
                  }}>
                  {errors.email.message}
                </p>
              )}
            </Box>

            <Box
              className={phoneClass}
              style={{
                marginBottom: "1rem",
              }}>
              <label className="cs-input-label cs-heading-color">
                {t("fields.phone")}
              </label>
              <input
                aria-required="true"
                {...register("phone", {
                  required: t("validation.required"),
                  pattern: {
                    value: /^[0-9()\s-+]+$/,
                    message: t("validation.phone"),
                  },
                  minLength: {
                    value: 10,
                    message: t("validation.phoneLength"),
                  },
                  maxLength: {
                    value: 10,
                    message: t("validation.phoneLength"),
                  },
                })}
                type="text"
                className="cs-form-field"
                placeholder={t("placeholders.phone")}
                aria-label={AL("inputs.phone")}
              />
              {errors.phone && (
                <p
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginBottom: 0,
                  }}>
                  {errors.phone.message}
                </p>
              )}
            </Box>
            {/* <div
              className="col-lg-6"
              style={{
                marginBottom: "1rem",
              }}>
              <label className="cs-input-label cs-heading-color">
                {t("date")}
              </label>
              <input
                {...register("date", {
                  required: true,
                  validate: (value) => {
                    const selectedDate = new Date(value);
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return selectedDate > today || "passedDate";
                  },
                })}
                type="date"
                className="cs-form-field"
                placeholder={t("placeholders.date")}
              />
              {errors.date && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  {errors.date.message === "passedDate"
                    ? t("passedDate")
                    : t("required")}
                </p>
              )}
            </div>
            <div className="col-lg-12">
              <label className="cs-input-label cs-heading-color">
                {t("comments")}
              </label>
              <textarea
                {...register("comments", {
                  required: false,
                })}
                cols={30}
                rows={5}
                className="cs-form-field"
                placeholder={t("placeholders.comments")}
              />
              {errors.comments && (
                <p style={{ color: "red", marginTop: "0.5rem" }}>
                  {t("required")}
                </p>
              )}
            </div>*/}
            <div className="col-lg-12">
              <button
                type="submit"
                id="booking_form_btn"
                className="cs-btn cs-style-1 submit-btn"
                style={{ fontWeight: "bold" }}>
                <span>{t("btnText")}</span>
                <i>
                  <CustomImage
                    src="/images/icons/arrow_white.svg"
                    alt="Icon"
                    height={11}
                    width={15}
                  />
                  <CustomImage
                    src="/images/icons/arrow_white.svg"
                    alt="Icon"
                    height={11}
                    width={15}
                  />
                </i>
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
