import * as React from 'react';
import Box from '@mui/material/Box';
import { usePathname, useRouter, } from 'next/navigation';
import { useLocale } from 'next-intl';
import { Typography } from '@mui/material';
import Image from 'next/image';
const LangToggle = ({ sx }) => {

    const router = useRouter();
    const pathname = usePathname();
    const currentLang = useLocale();
    const [isPending, startTransition] = React.useTransition();
    const [selectedLang, setSelectedLang] = React.useState(currentLang);

    React.useEffect(() => {
        if (currentLang !== selectedLang) {
            window.location.reload();
        }
    }, [selectedLang, currentLang]);

    const handleChange = () => {
        const nextLocale = currentLang === 'en' ? 'es' : 'en'
        startTransition(() => {
            setSelectedLang(nextLocale);
            router.push(`/${nextLocale}/${pathname.substring(3)}`);
        });
        setTimeout(() => {
            window.location.reload();
        }, 600);
    };

    return (
        <Box onClick={() => handleChange()}
            sx={{
                cursor: 'pointer', display: 'flex', color: 'inherit', fontWeight: '600', alignItems: "center", ...sx
            }}
            role="button"
            tabIndex={0}
        >
            {currentLang === 'en' ?
                <>
                    <Image
                        loading='lazy'
                        src='/images/icons/es.svg'
                        priority={false}
                        width={24}
                        height={24}
                        style={{
                            width: '1.8em',
                            height: '1.8em',
                            borderRadius: '5px',
                            marginRight: '0.5rem'
                        }}
                        title="ES"
                        alt='ES Flag'

                    />
                    <Typography sx={{ fontWeight: "inherit" }}>ES</Typography>
                </> :
                <>
                    <Image
                        loading='lazy'
                        src='/images/icons/us.svg'
                        priority={false}
                        width={24}
                        height={24}
                        style={{
                            width: '1.8em',
                            height: '1.8em',
                            borderRadius: '5px',
                            marginRight: '0.5rem'
                        }}
                        title="US"
                        alt='US Flag'

                    />
                    <Typography sx={{ fontWeight: "inherit" }}>EN</Typography>
                </>
            }
        </Box>
    )
}

export default LangToggle